/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import * as React from 'react'
import { Link, graphql } from 'gatsby'
import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'
import Img from 'gatsby-image'
import TextTruncate from 'react-text-truncate'
import { colors } from '../styles/variables'
import IndexLayout from '../layouts'
import { gridColumnsA, gridColumnsB, gridColumnsC } from '../layouts/gridLayouts'

const getGridTemplate = (option: Option) => {
  switch (option) {
    case 'wallA':
      return ['360px', '440px', '440px', '360px', '440px', '440px', '360px', '440px', '360px']
    case 'wallB':
      return ['360px', '440px', '440px', '360px', '440px', '360px', '440px', '440px', '360px']
    default:
      return ['440px', '360px', '440px', '440px', '360px', '440px', '360px', '440px', '360px']
  }
}

const SuggestionGrid = styled.div`
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  padding: 60px 150px;
  grid-template: 260px 260px 260px / 1fr 1fr 1fr;
  @media (max-width: 850px) {
    grid-template: 260px /1fr;
    grid-template-columns: 1fr;
  }

  @media (max-width: 650px) {
    padding: 42px 32px;
    grid-row-gap: 24px;
  }
`

const SuggestionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: ${colors.plain.nuances1Bis};
  margin-top: 72px;
  padding-bottom: 72px;
`

const Grid = styled.div`
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  padding: 60px 150px;
  grid-template: ${({ option }: { option: Option }) => getGridTemplate(option).join(' ')} / repeat(20, 1fr);
  @media (max-width: 850px) {
    grid-template: 500px /1fr;
    grid-template-columns: 1fr;
  }
  @media (max-width: 800px) {
    padding: 42px 32px;
  }
`

const Title = styled.h1`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  line-height: 130%;
  padding-top: 100px;
  text-align: center;
`

export const LinkWrapper = styled(Link, { shouldForwardProp: isPropValid })`
  grid-column: ${({ gridColumn }: { gridColumn: string }) => gridColumn};
  @media (max-width: 850px) {
    grid-column: 1;
  }
`

export const Card = styled.div`
  background: ${colors.gradient.nuances2};
  border-radius: 24px;
  display: flex;
  flex-direction: ${({ isCoverLeft }: { isCoverLeft?: boolean; isSuggestion?: boolean }) => (isCoverLeft ? 'row' : 'column')};
  @media (min-width: 900px) {
    & :hover {
      img {
        /* transform: scale(1.05); */
      }
      /* transform: ${({ isSuggestion }) => (isSuggestion ? 'scale(1.02)' : 'scale(1)')}; */
      transition: 200ms;
    }
  }
  @media (max-width: 850px) {
    flex-direction: column;
  }
  height: 100%;
`

const typeColors = {
  interview: '#FF71A5',
  article: '#82D6F0',
  'case study': '#C38EEB',
  video: '#FF71A5',
  book: '#C38EEB'
}

export const CardType = styled.div`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: ${({ type }: { type: string }) =>
    // @ts-ignore
    typeColors[type] ? typeColors[type] : 'red'};
`

export const CardTitle = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  height: 72px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const CardSubtitle = styled.div`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: ${colors.plain.nuances7};
`

export const Cover = styled(Img)`
  ${({ isCoverLeft }: { isCoverLeft: boolean }) => `
  object-fit: cover;
  width: ${isCoverLeft ? '70%' : '100%'};
  height: ${isCoverLeft ? '100%' : '60%'};
  border-radius: ${isCoverLeft ? '24px 0 0 24px' : '24px 24px 0 0'};
  background-position: center;
  background-size: cover;
  & img {
    transition: transform 200ms, opacity 200ms !important;
    border-radius: ${isCoverLeft ? '24px 0 0 24px' : '24px 24px 0 0'};
  }
  `}
  overflow: hidden;
  @media (max-width: 850px) {
    height: 380px;
    border-radius: 24px 24px 0 0;
    width: 100%;
    & img {
      border-radius: 24px 24px 0 0;
    }
  }
  @media (max-width: 650px) {
    height: 280px;
  }
`

export const CardContent = styled.div`
  display: flex;
  height: ${({ isCoverLeft }: { isCoverLeft?: boolean }) => (isCoverLeft ? '100%' : '40%')};
  justify-content: ${({ isCoverLeft }: { isCoverLeft?: boolean }) => (isCoverLeft ? 'center' : 'space-around')};
  flex-direction: column;
  padding: 24px;
`

interface SiteMetadata {
  title: string
}

interface Data {
  site: SiteMetadata
  allMarkdownRemark: { edges: Array<{ node: ContentType }> }
}

interface ContentType {
  frontmatter: {
    title: string
    type: string
    subtitle: string
    wallA: number
    wallB: number
    wallC: number
    suggestion: boolean
    externalLink: string
    cover: {
      publicURL: string
    }
  }
  fields: {
    slug: string
  }
}

interface ContentPageProps {
  data: Data
}

type Option = 'wallA' | 'wallB' | 'wallC'

const getGridColumns = (option: Option, index: number) => {
  switch (option) {
    case 'wallA':
      return gridColumnsA[index]
    case 'wallB':
      return gridColumnsB[index]
    default:
      return gridColumnsC[index]
  }
}

const sortBy = ['video', 'article', 'book']

const getOption = (options: Array<Option>, random: number): Option => options[random]
const ContentPage = ({ data }: ContentPageProps) => {
  const content = data?.allMarkdownRemark?.edges
  const contentPosts = content.filter(({ node }) => !node.frontmatter.suggestion)
  const suggestions = content.filter(({ node }) => node.frontmatter.suggestion)
  const random = Math.floor(Math.random() * Math.floor(3))
  const options: Array<Option> = ['wallA', 'wallB', 'wallC']
  const option = getOption(options, random)
  const sortedContent = contentPosts.sort((a, b) => a.node.frontmatter[option] - b.node.frontmatter[option])
  const sortByObject = sortBy.reduce((obj, item, index) => {
    return {
      ...obj,
      [item]: index
    }
  }, {})

  // @ts-ignore
  const sortedSuggestion = suggestions.sort((a, b) => sortByObject[a.node.frontmatter['type']] - sortByObject[b.node.frontmatter['type']])

  return (
    <IndexLayout>
      <Title>Get Inspired</Title>
      <Grid option={option}>
        {sortedContent.map(({ node }, index) => {
          const { title, subtitle, cover, type } = node.frontmatter
          const { slug } = node.fields
          const gridColumn = getGridColumns(option, index)
          const isCoverLeft = gridColumn[0] === 1 && gridColumn[1] === 21

          return (
            <LinkWrapper key={title} to={`/content${slug}`} gridColumn={`${gridColumn[0]}/${gridColumn[1]}`}>
              <Card isCoverLeft={isCoverLeft}>
                {/* @ts-ignore */}
                {cover && <Cover fluid={cover.childImageSharp?.fluid} sizes={cover?.childImageSharp?.sizes} isCoverLeft={isCoverLeft} />}
                <CardContent isCoverLeft={isCoverLeft}>
                  <CardType type={type}>{type}</CardType>

                  <CardTitle>
                    <TextTruncate line={2} element="span" truncateText="…" text={title} />
                  </CardTitle>

                  <CardSubtitle>{subtitle}</CardSubtitle>
                </CardContent>
              </Card>
            </LinkWrapper>
          )
        })}
      </Grid>

      <SuggestionContainer>
        <Title>What we liked</Title>
        <SuggestionGrid>
          {sortedSuggestion.map(({ node }) => {
            const { title, subtitle, type, externalLink } = node.frontmatter

            return externalLink ? (
              <a href={externalLink} rel="noreferrer" target="_blank" key={`card_${title}`} aria-label={title}>
                <Card isSuggestion>
                  <CardContent isCoverLeft={false} style={{ height: '100%' }}>
                    <CardType type={type}>{type}</CardType>
                    <CardTitle>{title}</CardTitle>
                    <CardSubtitle>{subtitle}</CardSubtitle>
                  </CardContent>
                </Card>
              </a>
            ) : (
              <Card key={`card_${title}`} isSuggestion>
                <CardContent style={{ height: '100%' }}>
                  <CardType type={type}>{type}</CardType>
                  <CardTitle>{title}</CardTitle>
                  <CardSubtitle>{subtitle}</CardSubtitle>
                </CardContent>
              </Card>
            )
          })}
        </SuggestionGrid>
      </SuggestionContainer>
    </IndexLayout>
  )
}

export const contentQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: { fields: { type: { eq: "content" } } }) {
      edges {
        node {
          frontmatter {
            title
            type
            subtitle
            wallA
            wallB
            wallC
            suggestion
            externalLink
            cover {
              publicURL
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default ContentPage

export const gridColumnsA = [
  // Row 1
  [1, 21],
  // Row 2
  [1, 10],
  [10, 21],
  // Row 3 = EQUAL
  [1, 11],
  [11, 21],
  // Row 4
  [1, 21],
  // Row 5
  [1, 10],
  [10, 21],
  // Row 6
  [1, 11],
  [11, 21],
  // Row 7
  [1, 21],
  // Row 8
  [1, 11],
  [11, 21],
  // Row 9
  [1, 21]
  // Row 10
  // [1, 11],
  // [11, 21]
]

export const gridColumnsB = [
  // Row 1
  [1, 21],
  // Row 2
  [1, 12],
  [12, 21],
  // Row 3 = EQUAL
  [1, 11],
  [11, 21],
  // Row 4
  [1, 21],
  // Row 5
  [1, 10],
  [10, 21],
  // Row 6
  [1, 21],
  // Row 7
  [1, 10],
  [10, 21],
  // Row 8
  [1, 11],
  [11, 21],
  // Row 9
  [1, 21]
  // Row 10
  // [1, 11],
  // [11, 21]
]

export const gridColumnsC = [
  // Row 1
  [1, 10],
  [10, 21],
  // Row 2
  [1, 21],
  // Row 3 = EQUAL
  [1, 11],
  [11, 21],
  // Row 4
  [1, 12],
  [12, 21],
  // Row 5
  [1, 21],
  // Row 6
  [1, 11],
  [11, 21],
  // Row 7
  [1, 21],
  // Row 8
  [1, 10],
  [10, 21],
  // Row 9
  [1, 21]
  // Row 10
  // [1, 11],
  // [11, 21]
]
